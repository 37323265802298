export default interface PaymentScheduledInterface {
  id: number
  nextPaymentDate: Date
  payerId: number
  paymentMethodId: number
  amountType: ScheduleAmountType
  amount: number
  firstPaymentDate: Date
  expirationDate: Date
  numberOfPayments: number
  expired: boolean
  frequency: ScheduleFrequency
  dayOfMonth: number
  monthInPeriod: number
  currencyCode: CurrencyCode
  memo: string
  fee: number
}

export enum ScheduleAmountType {
  Fixed = "Fixed",
  BalanceDue = "BalanceDue"
}

export enum ScheduleFrequency {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  SemiAnnually = "SemiAnnually",
  Annually = "Annually",
  OneTime = "OneTime"
}

export enum CurrencyCode {
  USD= "USD",
}
