/**
 * Homeowner Detail constants
 */
export const FETCH_HOMEOWNER_DETAILS = 'FETCH_HOMEOWNER_DETAILS'
export const RECEIVE_HOMEOWNER_DETAILS = 'RECEIVE_HOMEOWNER_DETAILS'
export const RECEIVE_PAYMENTS_SCHEDULED = 'RECEIVE_PAYMENTS_SCHEDULED'
export const REFRESH_PAYMENTS_SCHEDULED = 'REFRESH_PAYMENTS_SCHEDULED'
export const DELETE_PAYMENT_SCHEDULED = 'DELETE_PAYMENT_SCHEDULED'
export const SHOW_HOMEOWNER_DETAILS_LOADING = 'SHOW_HOMEOWNER_DETAILS_LOADING'
export const HIDE_HOMEOWNER_DETAILS_LOADING = 'HIDE_HOMEOWNER_DETAILS_LOADING'
export const OPEN_HOMEOWNER_DETAILS = 'OPEN_HOMEOWNER_DETAILS'
export const CLOSE_HOMEOWNER_DETAILS = 'CLOSE_HOMEOWNER_DETAILS'
export const TOGGLE_PAYER_ALLOW_PAYMENTS = 'TOGGLE_PAYER_ALLOW_PAYMENTS'
export const SELECT_HOMEOWNER_ROW = 'SELECT_HOMEOWNER_ROW'
export const SEND_SCHEDULE_PAYMENTS_DELETE_LINK = 'SEND_SCHEDULE_PAYMENTS_DELETE_LINK'
