import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
    DELETE_DASHBOARD_USER,
    FETCH_DASHBOARD_USER_INITIAL_DATA,
    FETCH_DASHBOARD_USERS,
    UPDATE_DASHBOARD_USER_FILTER
} from "../constants/dashboardUserConstants";
import {
    DeleteDashboardUserAction,
    FetchDashboardUsersAction,
    UpdateDashboardUserFilterAction
} from "../actions/dashboard.user.action";
import { fetchDashboardUsers, hideDashboardUserLoading, receiveDashboardUserInitialData, receiveDashboardUsers, showDashboardUserLoading } from "../actions/dashboardUser";
import { DatatableResponseInterface } from "../interfaces/datatable.response.interface";
import { catchException } from "./errorHandlerSaga";
import { DashboardUserDatatableRequestInterface } from "../interfaces/datatable.request.interface";
import { convertToDashboardUsersRequest, getNotificationFormat } from "../utils/helpers";
import { deleteDashboardUserAPI, fetchDashboardUserAPI, fetchManagementCompanies } from "../api/Auth0API";
import { sendNotification } from "../actions/notificationHandler";
import { DatatableRowInterface } from "../interfaces/datatable.row.interface";
import { watchSendSchedulePaymentsDeleteLink } from "./homeownerDetailsSaga";

/**
 * Saga to observe FETCH_DASHBOARD_USERS action
 */
export function* watchRequestDashboardUsersSaga() {
    yield takeLatest(FETCH_DASHBOARD_USERS, requestDashboardUsersSaga)
}

/**
 * Resolver for FETCH_DASHBOARD_USERS
 * @param action
 */
function* requestDashboardUsersSaga(action: FetchDashboardUsersAction) {
    try {
        yield put(showDashboardUserLoading)
        const response: DatatableResponseInterface = yield call(fetchDashboardUserAPI, action.request)
        yield put(receiveDashboardUsers(response))
    } catch (e) {
        console.warn('Error in requestDashboardUsersSaga: ', e)
        yield catchException(e)
    } finally {
        yield put(hideDashboardUserLoading)
    }

}

/**
 * Saga to observe UPDATE_DASHBOARD_USER_FILTER action
 */
export function* watchUpdateFiltersSaga() {
    yield takeLatest(UPDATE_DASHBOARD_USER_FILTER, updateFilterSaga);
}

/**
 * Resolver for UPDATE_DASHBOARD_USER_FILTER
 * @param action
 */
function* updateFilterSaga(action: UpdateDashboardUserFilterAction) {
    if (!action.cleanup) {
        const request: DashboardUserDatatableRequestInterface = convertToDashboardUsersRequest(action.payload);
        yield put(fetchDashboardUsers(request));
    } else {
        yield put(receiveDashboardUsers({
            offset: 0,
            limit: 0,
            itemCount: 0,
            items: []
        }))
    }
}

/**
 * Saga to observe FETCH_DASHBOARD_USER_INITIAL_DATA
 */
export function* watchFetchInitialDataSaga() {
    yield takeLatest(FETCH_DASHBOARD_USER_INITIAL_DATA, fetchInitialDataSaga);
}

/**
 * Resolve FETCH_DASHBOARD_USER_INITIAL_DATA
 */
function* fetchInitialDataSaga() {
    try {
        const managementCompanies = yield call(fetchManagementCompanies);
        yield put(receiveDashboardUserInitialData(managementCompanies))
    } catch (e) {
        console.warn('Error in fetchInitialDataSaga: ', e)
        yield catchException(e)
    }
}

/**
 * Saga to observe DELETE_DASHBOARD_USER
 */
export function* watchDeletePaymentScheduled() {
    yield takeLatest(DELETE_DASHBOARD_USER, deleteDashboardUserSaga);
}

/**
 * Resolve DELETE_DASHBOARD_USER
 */
function* deleteDashboardUserSaga(action: DeleteDashboardUserAction) {
    try {
        yield put(showDashboardUserLoading)
        yield call(deleteDashboardUserAPI, action.dashboardUserId)
        const request: DashboardUserDatatableRequestInterface = convertToDashboardUsersRequest(action.payload);
        yield put(fetchDashboardUsers(request));
        yield put(sendNotification(getNotificationFormat(
            'success',
            `Success! The user ${action.emailAddress} has been deleted.`
        )));
    } catch (e) {
        console.warn('Error in deleteDashboardUserSaga: ', e)
        yield catchException(e)
    }
    yield put(hideDashboardUserLoading)
}

export function* dashboardUsersSagas() {
    yield all([
        fork(watchUpdateFiltersSaga),
        fork(watchFetchInitialDataSaga),
        fork(watchRequestDashboardUsersSaga),
        fork(watchDeletePaymentScheduled),
        fork(watchSendSchedulePaymentsDeleteLink)

    ]);
}
