import {HomeownersInterface} from "../store";
import {config} from "../config";
import {HomeownerTypes, SelectHomeownerRowAction} from "../actions/homeowners.action";
import {
  HIDE_HOMEOWNERS_LOADING,
  RECEIVE_HOMEOWNERS,
  RECEIVE_HOMEOWNERS_INITIAL_DATA,
  SELECT_HOMEOWNER,
  SHOW_HOMEOWNERS_LOADING,
  TOGGLE_SCHEDULED_PAYMENTS_DIALOG,
  UPDATE_HOMEOWNERS_FILTER
} from "../constants/homeowners";
import {SELECT_HOMEOWNER_ROW} from "../constants/homeownerDetails";
import {HomeownerInterface} from "../interfaces/homeowner.interface";

export const homeownersReducer = (state: HomeownersInterface = getHomeownersInitialState(), action : HomeownerTypes): HomeownersInterface => {
  switch (action.type) {
    case UPDATE_HOMEOWNERS_FILTER:
      return {
        ...state,
        filters: {
          ...action.payload
        }
      }
    case RECEIVE_HOMEOWNERS:
      return {
        ...state,
        homeownerSelectedIds: [],
        dataSource: {
          ...state.dataSource,
          ...action.payload
        }
      }
    case RECEIVE_HOMEOWNERS_INITIAL_DATA:
      return {
        ...state,
        initialized: true,
        catalog: {
          ...state.catalog,
          communities: action.communities
        }
      }
    case SELECT_HOMEOWNER:
      return {
        ...state,
        homeownerSelectedId: action.id
      }
    case SHOW_HOMEOWNERS_LOADING:
      return {
        ...state,
        loading: true
      }
    case HIDE_HOMEOWNERS_LOADING:
      return {
        ...state,
        loading: false
      }
    case SELECT_HOMEOWNER_ROW:
      const payload = action as SelectHomeownerRowAction;
      if (payload.scheduledPaymentId == 0) {
        return {
          ...state,
          allSelected: payload.checked,
          homeownerSelectedIds: payload.checked  ? [...getHomeownerScheduledPaymentsId(state.dataSource.items as HomeownerInterface[])] : [],
          dataSource: {
            ...state.dataSource,
            items: state.dataSource.items.map((item) => {
              const homeowner = item as HomeownerInterface;
              if (homeowner.scheduledPaymentId > 0) {
                homeowner.isSelected = payload.checked;
              }
              return item;
            })
          }
        }
      }
      return {
        ...state,
        allSelected: false,
        homeownerSelectedIds: payload.checked && state.homeownerSelectedIds ? [...state.homeownerSelectedIds, payload.scheduledPaymentId] :
          (state.homeownerSelectedIds ? state.homeownerSelectedIds.filter((id) => id != payload.scheduledPaymentId) : [payload.scheduledPaymentId]),
        dataSource: {
          ...state.dataSource,
          items: state.dataSource.items.map((item) => {
            const homeowner = item as HomeownerInterface;
            if (homeowner.scheduledPaymentId == payload.scheduledPaymentId) {
              homeowner.isSelected = payload.checked;
            }
            return item;
          })
        }
      }
      
    case TOGGLE_SCHEDULED_PAYMENTS_DIALOG: {
      return {
        ...state,
        scheduledPaymentsUpdateDialogOpen: action.isOpen
      }
    }
    default:
      return state
  }
}

const getHomeownersInitialState = (): HomeownersInterface => {
  return {
    filters: {
      pageNumber: 1,
      pageSize: config.homeownersPageSize
    },
    initialized: false,
    loading: false,
    catalog: {
      paymentTypes: [{paymentMethodTypeName: 'Future'}]
    },
    dataSource: {limit: config.homeownersPageSize, offset: 0, itemCount: 0, items: []},
    allSelected: false,
    homeownerSelectedIds: []
  }
}

const getHomeownerScheduledPaymentsId = (payments: HomeownerInterface[]): number[] => {
  return payments.filter((payment) => payment.scheduledPaymentId > 0 && payment.amount > 0).map((payment) => payment.scheduledPaymentId);
}
