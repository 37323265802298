export interface ScheduledReportInterface {
  id?: number;
  name: string;
  frequency: ReportFrequency;
  day: DayOfWeek;
  statusCriteria: string;
  ownerEmailAddress: string;
}

export enum ReportFrequency
{
  Daily= 'Daily',
  Weekly= 'Weekly'
}

export enum DayOfWeek
{
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}
