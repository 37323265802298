import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import {
    CLOSE_HOMEOWNER_DETAILS,
    DELETE_PAYMENT_SCHEDULED,
    SEND_SCHEDULE_PAYMENTS_DELETE_LINK,
    FETCH_HOMEOWNER_DETAILS,
    OPEN_HOMEOWNER_DETAILS, TOGGLE_PAYER_ALLOW_PAYMENTS
} from "../constants/homeownerDetails";
import {
    deletePaymentScheduledById,
    sendSchedulePaymentsDeleteLink,
    getFeePaymentScheduled,
    getHomeownerDetails,
    getPaymentMethod,
    getPaymentsScheduledByPayerId, updateAllowPaymentsApi
} from "../api/HomeownersAPI";
import {
    hideLoading,
    receiveHomeownerDetails,
    receivePaymentsScheduled,
    showLoading,
    togglePayerAllowPayments
} from "../actions/homeownerDetails";
import { catchException } from "./errorHandlerSaga";
import {
    DeletePaymentScheduledAction,
    SendSchedulePaymentsDeleteLink,
    FetchHomeownerDetailsAction,
    OpenHomeownerDetailsAction, TogglePayerAllowPaymentsAction
} from "../actions/homeowner.details.action";
import { DatatableResponseInterface } from "../interfaces/datatable.response.interface";
import PayerDetailsInterface from "../interfaces/payer.details.interface";
import PaymentScheduledInterface, { ScheduleAmountType } from "../interfaces/payment.scheduled.interface";
import { fetchHomeowners, selectHomeowner } from "../actions/homeowners";
import PaymentMethod from "../models/payment.method.model";
import { HomeownersDatatableRequestInterface } from "../interfaces/homeowners.datatable.request.interface";
import { convertToHomeownersRequest } from "../utils/helpers";
import { DatatableRequestInterface } from "../interfaces/datatable.request.interface";
import { getPaymentTransactions } from "../api/PaymentTransactionAPI";

/**
 * Saga to observe FETCH_HOMEOWNER_DETAILS
 */
export function* watchFetchHomeownerDetails() {
    yield takeLatest(FETCH_HOMEOWNER_DETAILS, fetchHomeownerDetailsSaga);
}

/**
 * Resolve FETCH_INITIAL_DATA
 */
function* fetchHomeownerDetailsSaga(action: FetchHomeownerDetailsAction) {
    try {
        const payerDetails: PayerDetailsInterface = yield call(getHomeownerDetails, action.id);
        const paymentsScheduled: PaymentScheduledInterface[] = yield call(getPaymentsScheduledByPayerId, action.id);
        const request: DatatableRequestInterface = {
            payerID: action.id,
            pageNumber: 0,
            pageSize: 5
        }
        const recentTransactions: DatatableResponseInterface = yield call(getPaymentTransactions, request)
        const itemCount = paymentsScheduled ? paymentsScheduled.length : 0
        for (let index = 0; index < itemCount; index++) {
            if (paymentsScheduled[index].amountType === ScheduleAmountType.Fixed) {
                const method = yield call(getPaymentMethod, paymentsScheduled[index].paymentMethodId)
                const paymentMethod = new PaymentMethod(method)
                paymentsScheduled[index].fee = yield call(getFeePaymentScheduled, payerDetails.communityId, paymentMethod.paymentType, paymentsScheduled[index].amount)
            }
        }
        const dataSource: DatatableResponseInterface = {
            limit: itemCount,
            offset: 0,
            items: paymentsScheduled,
            itemCount
        }
        yield put(receiveHomeownerDetails(payerDetails, dataSource, recentTransactions))
    } catch (e) {
        console.warn('Error in fetchHomeownerDetailsSaga: ', e)
        yield catchException(e)
    }
}


/**
 * Saga to observe DELETE_PAYMENT_SCHEDULED
 */
export function* watchDeletePaymentScheduled() {
    yield takeLatest(DELETE_PAYMENT_SCHEDULED, deletePaymentScheduledSaga);
}

/**
 * Saga to observe SEND_SCHEDULE_PAYMENTS_DELETE_LINK
 */
export function* watchSendSchedulePaymentsDeleteLink() {
    yield takeLatest(SEND_SCHEDULE_PAYMENTS_DELETE_LINK, sendSchedulePaymentsDeleteLinkSaga);
}
/**
 *  SendSchedulePaymentsDeleteLink
 * @param action
 */
function* sendSchedulePaymentsDeleteLinkSaga(action: SendSchedulePaymentsDeleteLink) {
    let success = false;

    yield put(showLoading);
    try {
        if (action.email) {
            yield call(sendSchedulePaymentsDeleteLink, action.payerId, action.email);
            success = true;
        }
    } catch (e) {
        console.warn('Error in sendSchedulePaymentsDeleteLinkSaga: ', e)
        yield catchException(e);
    } finally {
        yield put(hideLoading)
    }
}

/**
 * Resolve DELETE_PAYMENT_SCHEDULED
 */
function* deletePaymentScheduledSaga(action: DeletePaymentScheduledAction) {
    try {
        yield put(showLoading)
        yield call(deletePaymentScheduledById, action.id)
        const paymentsScheduled: PaymentScheduledInterface[] = yield call(getPaymentsScheduledByPayerId, action.payerId);
        const itemCount = paymentsScheduled ? paymentsScheduled.length : 0
        for (let index = 0; index < itemCount; index++) {
            const method = yield call(getPaymentMethod, paymentsScheduled[index].paymentMethodId)
            const paymentMethod = new PaymentMethod(method)
            paymentsScheduled[index].fee = yield call(getFeePaymentScheduled, action.communityId, paymentMethod.paymentType, paymentsScheduled[index].amount)
        }
        const dataSource: DatatableResponseInterface = {
            limit: itemCount,
            offset: 0,
            items: paymentsScheduled,
            itemCount
        }
        yield put(receivePaymentsScheduled(dataSource))
    } catch (e) {
        console.warn('Error in deletePaymentScheduledSaga: ', e)
        yield catchException(e)
    }
    yield put(hideLoading)
}

export function* watchOpenHomeownerDetailsSaga() {
    yield takeLatest(OPEN_HOMEOWNER_DETAILS, openHomeownerDetailsSaga)
}

function* openHomeownerDetailsSaga(action: OpenHomeownerDetailsAction) {
    yield put(selectHomeowner(action.payerId))
}

export function* watchCloseHomeownerDetailsSaga() {
    yield takeLatest(CLOSE_HOMEOWNER_DETAILS, closeHomeownerDetailsSaga)
}

function* closeHomeownerDetailsSaga() {
    yield put(selectHomeowner(0))
}

export function* watchToggleAllowPaymentsSaga() {
    yield takeLatest(TOGGLE_PAYER_ALLOW_PAYMENTS, togglePayerAllowPaymentsSaga)
}

/**
 * Resolve TOGGLE_PAYER_ALLOW_PAYMENTS
 */
function* togglePayerAllowPaymentsSaga(action: TogglePayerAllowPaymentsAction) {
    try {
        const state = yield select();
        yield put(showLoading)
        yield call(updateAllowPaymentsApi, action.payerId, action.allowPayments)
        const req: HomeownersDatatableRequestInterface = convertToHomeownersRequest(state.homeowner.filters);
        yield put(fetchHomeowners(req))
    } catch (e) {
        console.warn('Error in togglePayerAllowPaymentsSaga: ', e)
        yield catchException(e)
    }
    yield put(hideLoading)
}

export function* homeownerDetailsSagas() {
    yield all([
        fork(watchFetchHomeownerDetails),
        fork(watchDeletePaymentScheduled),
        fork(watchCloseHomeownerDetailsSaga),
        fork(watchOpenHomeownerDetailsSaga),
        fork(watchToggleAllowPaymentsSaga)
    ]);
}
