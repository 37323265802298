import {
    FETCH_HOMEOWNERS,
    FETCH_HOMEOWNERS_INITIAL_DATA,
    HIDE_HOMEOWNERS_LOADING,
    RECEIVE_HOMEOWNERS,
    RECEIVE_HOMEOWNERS_INITIAL_DATA,
    SELECT_HOMEOWNER,
    SHOW_HOMEOWNERS_LOADING,
    TOGGLE_SCHEDULED_PAYMENTS_DIALOG,
    UPDATE_HOMEOWNERS_FILTER,
} from "../constants/homeowners"
import {FiltersHomeownersInterface} from "../store";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {DatatableRequestInterface} from "../interfaces/datatable.request.interface";
import {CommunityInterface} from "../interfaces/community.interface";
import {
    FetchHomeownersAction,
    ReceiveHomeownersAction,
    ReceiveInitialDataAction,
    ScheduledPaymentsUpdateDialog,
    SelectHomeownerAction,
    UpdateFilterAction
} from "./homeowners.action";

export const updateFilter = (payload: FiltersHomeownersInterface) : UpdateFilterAction => {
    return {
        type: UPDATE_HOMEOWNERS_FILTER,
        payload
    }
}

export const fetchHomeowners = (request: DatatableRequestInterface) : FetchHomeownersAction => {
    return {
        type: FETCH_HOMEOWNERS,
        request: request
    }
}

export const receiveHomeowners = (payload: DatatableResponseInterface) : ReceiveHomeownersAction => {
    return {
        type: RECEIVE_HOMEOWNERS,
        payload
    }
}

export const fetchInitialData = {
    type: FETCH_HOMEOWNERS_INITIAL_DATA
}

export const receiveInitialData = (communities: CommunityInterface[]) : ReceiveInitialDataAction => {
    return {
        type: RECEIVE_HOMEOWNERS_INITIAL_DATA,
        communities
    }
}

export const selectHomeowner = (id: number) : SelectHomeownerAction => {
    return {
        type: SELECT_HOMEOWNER,
        id
    }
}

export const showLoading = {
    type: SHOW_HOMEOWNERS_LOADING
}

export const hideLoading = {
    type: HIDE_HOMEOWNERS_LOADING
}

export const toggleScheduledPaymentsDialog = (isOpen: boolean) : ScheduledPaymentsUpdateDialog => {
    return {
        type: TOGGLE_SCHEDULED_PAYMENTS_DIALOG,
        isOpen
    }
}
