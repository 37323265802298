import {all, fork, debounce, put, call, takeLatest, select} from 'redux-saga/effects';
import {DatatableRequestInterface} from "../interfaces/datatable.request.interface";
import {getInitialData, getHomeowners} from "../api/HomeownersAPI";
import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {FetchHomeownersAction, ScheduledPaymentsUpdateDialog, UpdateFilterAction} from "../actions/homeowners.action";
import {fetchHomeowners, receiveHomeowners, receiveInitialData, showLoading, hideLoading} from "../actions/homeowners";
import {
  FETCH_HOMEOWNERS_INITIAL_DATA,
  FETCH_HOMEOWNERS,
  UPDATE_HOMEOWNERS_FILTER, TOGGLE_SCHEDULED_PAYMENTS_DIALOG
} from "../constants/homeowners";
import {convertToHomeownersRequest, getHomeownersInitialRequest} from "../utils/helpers";
import {catchException} from "./errorHandlerSaga";
import {HomeownersDatatableRequestInterface} from "../interfaces/homeowners.datatable.request.interface";
import {ApplicationState} from "../store";

/**
 * Saga to observe FETCH_HOMEOWNERS action
 */
export function* watchRequestHomeownersSaga() {
  yield takeLatest(FETCH_HOMEOWNERS, requestHomeownersSaga)
}

/**
 * Resolver for FETCH_HOMEOWNERS
 * @param action
 */
function* requestHomeownersSaga(action: FetchHomeownersAction) {
  try {
    yield put(showLoading)
    const response: DatatableResponseInterface = yield call(getHomeowners, action.request)
    yield put(receiveHomeowners(response))
  } catch (e) {
    console.warn('Error in requestHomeownersSaga: ', e)
    yield catchException(e)
  } finally {
    yield put(hideLoading)
  }
  
}

/**
 * Saga to observe UPDATE_FILTER action
 */
export function* watchUpdateFiltersSaga() {
  yield debounce(1000, UPDATE_HOMEOWNERS_FILTER, updateFilterSaga);
}

/**
 * Resolver for UPDATE_FILTER
 * @param action
 */
function* updateFilterSaga(action: UpdateFilterAction) {
  const req: HomeownersDatatableRequestInterface = convertToHomeownersRequest(action.payload);
  yield put(fetchHomeowners(req))
}

/**
 * Saga to observe FETCH_INITIAL_DATA
 */
export function* watchFetchInitialDataSaga() {
  yield takeLatest(FETCH_HOMEOWNERS_INITIAL_DATA, fetchInitialDataSaga);
}

/**
 * Resolve FETCH_INITIAL_DATA
 */
function* fetchInitialDataSaga() {
  try {
    const {communities} = yield call(getInitialData);
    yield put(receiveInitialData(communities))
    const request: DatatableRequestInterface = getHomeownersInitialRequest()
    const response = yield call(getHomeowners, request)
    yield put(receiveHomeowners(response))
  } catch (e) {
    console.warn('Error in fetchInitialDataSaga: ', e)
    yield catchException(e)
  }
}

/**
 * Saga to observe TOGGLE_SCHEDULED_PAYMENTS_DIALOG
 */
export function* watchToggleScheduledPaymentsUpdateDialogSaga() {
  yield takeLatest(TOGGLE_SCHEDULED_PAYMENTS_DIALOG, toggleScheduledPaymentsUpdateDialogSaga);
}

function* toggleScheduledPaymentsUpdateDialogSaga(action: ScheduledPaymentsUpdateDialog) {
  try {
    if (!action.isOpen) {
      const state: ApplicationState = yield select();
      const req: HomeownersDatatableRequestInterface = convertToHomeownersRequest(state.homeowner.filters);
      yield put(fetchHomeowners(req))
    }
  } catch (e) {
    console.warn('Error in toggleScheduledPaymentsUpdateDialogSaga: ', e)
    yield catchException(e)
  }
}

export function* homeownerSagas() {
  yield all([
    fork(watchRequestHomeownersSaga),
    fork(watchUpdateFiltersSaga),
    fork(watchFetchInitialDataSaga),
    fork(watchToggleScheduledPaymentsUpdateDialogSaga),
  ]);
}
