import {DatatableResponseInterface} from "../interfaces/datatable.response.interface";
import {config} from '../config'
import {DatatableRequestInterface} from "../interfaces/datatable.request.interface";
import {CommunityInterface} from "../interfaces/community.interface";
import httpService from "./HttpService";
import {getURLSearchParams} from "../utils/helpers";
import PayerDetailsInterface from "../interfaces/payer.details.interface";
import PaymentScheduledInterface from "../interfaces/payment.scheduled.interface";
import {FeeTypeEnum} from "../interfaces/fee.type.enum";
import {PaymentTypeEnum} from "../interfaces/payment.type.enum";
import PaymentMethodInterface from "../interfaces/payment.method.interface";
import fileDownload from "js-file-download";
import {HomeownersDatatableRequestInterface} from "../interfaces/homeowners.datatable.request.interface";
import {ScheduledPaymentsBatchRequestInterface} from "../interfaces/scheduled.payments.batch.request.interface";

const HOMEOWNERS_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/payers/all`
const COMMUNITIES_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/communities/?onboardingstatus=onboarded`
const HOMEOWNER_DETAILS_URL = (payerId: number) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/payers/${payerId}/details`
const PAYMENTS_SCHEDULED_URL = (payerId: number) =>`${config.baseUrl}${config.suffixApi}${config.versionApi}/paymentschedules/bypayer/${payerId}`
const DELETE_PAYMENT_SCHEDULED_URL = (paymentId: number) =>`${config.baseUrl}${config.suffixApi}${config.versionApi}/paymentschedules/${paymentId} `
const FEE_PAYMENT_SCHEDULED_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/fees`
const PAYMENT_METHODS_URL = (id: number) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/paymentmethods/${id}`
const HOMEOWNER_UPDATE_EMAIL_URL = (payerId: number) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/payers/${payerId}/updateEmail`
const SEND_RECEIPT_URL = (paymentId: number) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/payments/${paymentId}/sendReceipt`
const SEND_DELETE_SCHEDULE_URL = (payerId: number) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/paymentschedules/sendDeleteScheduleLink/${payerId}`
const HOMEOWNER_UPDATE_ALLOW_PAYMENTS_URL = (payerId: number) => `${config.baseUrl}${config.suffixApi}${config.versionApi}/payers/${payerId}/updateAllowPayments`
const HOMEOWNERS_EXPORT_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/payers/export`
const HOMEOWNERS_UPDATE_SCHEDULED_PAYMENTS_URL = `${config.baseUrl}${config.suffixApi}${config.versionApi}/payers/update-scheduled-payments`


export const getInitialData = () => {
  return Promise.all([
    getCommunities()
  ]).then(([communities]) => ({
    communities
  }))
}
export const getHomeowners = (request: HomeownersDatatableRequestInterface):Promise<DatatableResponseInterface> => {
  return httpService.get<DatatableResponseInterface>(HOMEOWNERS_URL, getURLSearchParams(request))
}

export const getCommunities = (): Promise<CommunityInterface[]> => {
  return httpService.get<CommunityInterface[]>(COMMUNITIES_URL)
}

export const getHomeownerDetails = (id: number): Promise<PayerDetailsInterface> => {
  return httpService.get<PayerDetailsInterface>(HOMEOWNER_DETAILS_URL(id))
}

export const getPaymentsScheduledByPayerId = (id: number): Promise<PaymentScheduledInterface[]> => {
  return httpService.get<PaymentScheduledInterface[]>(PAYMENTS_SCHEDULED_URL(id))
}

export const deletePaymentScheduledById = (id: number): Promise<void> => {
  return httpService.delete<void>(DELETE_PAYMENT_SCHEDULED_URL(id))
}

export const sendSchedulePaymentsDeleteLink = (id: number, email: string): Promise<void> => {    
    return httpService.post<void>(SEND_DELETE_SCHEDULE_URL(id), `"${email}"`)
}

export const getFeePaymentScheduled = (communityId: number, paymentType: PaymentTypeEnum, amount: number): Promise<number> => {
  return httpService.get<number>(FEE_PAYMENT_SCHEDULED_URL, getURLSearchParams({
    communityId, paymentType, amount, feeType: FeeTypeEnum.Recurring
  }))
}

export const getPaymentMethod = (paymentMethodId: number): Promise<PaymentMethodInterface> => {
  return httpService.get<PaymentMethodInterface>(PAYMENT_METHODS_URL(paymentMethodId))
}

export const updateHomeownerEmailApi = (payerId: number, email: string): Promise<void> => {
  return httpService.post(HOMEOWNER_UPDATE_EMAIL_URL(payerId), `"${email}"`);
}

export const sendReceiptApi = (paymentId: number): Promise<void> => {
  return httpService.post(SEND_RECEIPT_URL(paymentId));
}

export const updateAllowPaymentsApi = (payerId: number, allowPayments: boolean): Promise<void> => {
  return httpService.post(HOMEOWNER_UPDATE_ALLOW_PAYMENTS_URL(payerId), `"${allowPayments}"`);
}

export const downloadFile = (fileName: string, params: any) => {
  httpService.get(HOMEOWNERS_EXPORT_URL, getURLSearchParams(params))
    .then((data: any) => {
      fileDownload(data, fileName);
    });
}

export const updateScheduledPayments = (scheduledPaymentsBatchRequest: ScheduledPaymentsBatchRequestInterface): Promise<void> => {
  return httpService.post(HOMEOWNERS_UPDATE_SCHEDULED_PAYMENTS_URL, scheduledPaymentsBatchRequest);
}
